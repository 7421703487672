@import './shared';

.raised-button {
  display: inline-block;
  position: relative;
  line-height: 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
  color: $raised-button-font-color;
  background-color: $raised-button-background-color;

  svg {
    fill: $raised-button-font-color;
  }

  &.raised-button-disabled {
    cursor: not-allowed;
    color: $raised-button-disabled-font-color;
    background-color: $raised-button-disabled-background-color;

    svg {
      fill: $raised-button-disabled-font-color;
      opacity: 0.4;
    }
  }

  &:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
  }

  &:focus,
  &.raised-button-focused {
    // set a blue focus outline for both desktop and mobile
    outline: 2px auto $browser-focus-outline-color !important;

    @supports (-ms-ime-align: auto) {
      // Edge does not support outline-style: auto
      outline: 2px solid $browser-focus-outline-color !important;
    }
  }
}
