@import './shared';

.app-header {
  position: sticky;
  top: 0;
  z-index: 100;
  height: auto;
  line-height: inherit;
  background: $header-color;
  box-shadow: 0 3px 5px rgba(100, 100, 100, 0.49);
  padding: $header-padding-vertical $header-padding-horizontal-lg;

  @include media(tablet) {
    padding: $header-padding-vertical $header-padding-horizontal-sm;
  }
}

.app-header-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-header-charms {
  position: absolute;
  right: 0;
  line-height: 0;
  margin-right: 33px;

  @include media(phone) {
    margin-right: 0;
  }
}
