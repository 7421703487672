.scalable-character {
  // needed for Edge
  width: 100%;

  // so the focus outline fits the cell perfectly
  vertical-align: middle;
}

.scalable-character-text {
  // needed for Edge
  line-height: normal;
}
