@import './shared';

.game {
  display: grid;
  grid-gap: $component-padding-lg;
  grid-template-areas:
    'header header'
    'main side';
  grid-template-columns: minmax($main-content-min-width-desktop, 1fr) 3fr;

  &.increase-kanji-size {
    grid-template-columns: minmax($main-content-min-width-desktop, 1fr) 1fr;
  }

  &,
  &.increase-kanji-size {
    @include media(tablet) {
      grid-template-areas:
        'header'
        'main'
        'side';
      grid-template-columns: auto;
    }
  }
}

.game-header-content {
  grid-area: header;
}

.game-main-content {
  grid-area: main;
}

.game-side-content {
  grid-area: side;

  // so side content does not affect css grid sizing
  min-width: 0;
}
