@import './shared';

.kanji-selections {
  display: flex;
}

.kanji-selections-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 (-$component-padding-sm) (-$component-padding-sm) 0;
}

.kanji-selections-selection {
  margin: 0 $component-padding-sm $component-padding-sm 0;
}

.kanji-selections-prompt {
  font-size: $font-size-xl;
}
