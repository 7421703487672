@import './shared';

.control-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 (-$component-padding-lg) (-$component-padding-md) 0;

  .level-picker,
  .control-panel-buttons {
    margin: 0 $component-padding-lg $component-padding-md 0;
  }

  .control-panel-buttons {
    display: grid;
    grid-auto-flow: column;
    grid-gap: $component-padding-lg;
  }
}
