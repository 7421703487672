@import './shared';

.game-preferences {
  display: grid;
  grid-auto-flow: row;
  grid-gap: $component-padding-md;
}

.game-preferences-desktop-only {
  @include media(tablet) {
    display: none;
  }
}
