@import './shared';

$size: 25%;

.scalable-selection-icon {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  width: $size;
  height: $size;

  &.scalable-selection-icon-visible {
    display: block;
  }
}

.scalable-selection-icon-poly {
  fill: $kanji-selection-color;
}
