@import './shared';

.label {
  display: block;
  color: $primary-font-color;
  font-size: $font-size-md;
  font-weight: $normal-font-weight;

  &.label-inline {
    display: inline-block;
    vertical-align: middle;
    margin-right: $component-padding-md;
  }

  &.label-secondary {
    color: $secondary-font-color;
  }

  &.label-large {
    font-size: $font-size-lg;
  }

  &.label-bold {
    font-weight: $bold-font-weight;
  }
}
