@import './shared';

html,
body {
  height: auto !important;
}

body {
  // to prevent the vertical scrollbar from shifting the page
  width: 100vw !important;
  overflow-x: hidden !important;
  margin: 0 !important;
}

.ant-notification-notice-with-empty-description .ant-notification-notice-message {
  margin-bottom: 0 !important;
}
