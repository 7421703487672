@import './shared';

.kanji-cell {
  .kanji-cell-link {
    // to ensure that the link fills the cell
    display: block;

    // !important is needed for Edge
    text-decoration: none !important;
  }

  &.kanji-cell-highlighted {
    background-color: $highlight-color;
  }

  &.kanji-cell-solved .kanji-cell-link {
    // set visibility to preserve sizing
    visibility: hidden;
  }

  &.kanji-cell-disabled,
  &.kanji-cell-solved {
    cursor: default;

    .kanji-cell-link {
      cursor: default;
    }
  }
}
