@import './shared';

.progress-summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  min-height: $tab-panel-min-height;

  // space-evenly does not work in Edge and the pseudo element workaround
  // does not support flex-wrap, so just use space-around for Edge
  @supports (-ms-ime-align: auto) {
    justify-content: space-around;
  }
}

.progress-summary-indicator {
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  grid-gap: $component-padding-md;
  padding: $component-padding-md;
}
