@import './shared';

.settings-drawer {
  .ant-drawer-title,
  .ant-drawer-close-x {
    font-size: $font-size-xl;
  }

  .ant-drawer-header {
    padding: $component-padding-lg;
  }

  .ant-drawer-body {
    padding: $component-padding-md $component-padding-lg;

    .ant-collapse-borderless {
      background: white;
    }
  }
}
