@import './shared';

.level-picker.level-picker-disabled {
  .ant-pagination-item-link {
    color: $disabled-font-color !important;
  }

  input {
    color: $disabled-font-color;

    // disable focus and active border colors
    border-color: $disabled-input-border-color !important;
  }
}
