@import './shared';

.side-panel {
  &.ant-tabs-vertical {
    > .ant-tabs-right-bar .ant-tabs-tab {
      padding: $component-padding-md $component-padding-lg;
    }

    > .ant-tabs-content {
      padding-right: $component-padding-lg;
    }
  }

  &.ant-tabs-top {
    > .ant-tabs-top-bar .ant-tabs-tab {
      @include media(phone) {
        // reduce space between tabs
        margin: 0;
      }
    }
  }
}
