@import './shared';

.main-panel {
  display: grid;
  align-content: start;
  grid-gap: $component-padding-lg;

  &.keep-kanji-in-view {
    position: sticky;
    top: $main-content-sticky-top;
  }
}
