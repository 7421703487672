@import './shared';

.icon-button {
  line-height: 0;
  vertical-align: middle;
  padding: $component-padding-sm;

  &.icon-button-medium {
    padding: $component-padding-md;
  }
}
