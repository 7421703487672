@import './shared';

$transition-period: 0.3s;

.content-button {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  color: $primary-font-color;
  transition: color $transition-period;

  > svg {
    fill: $primary-font-color;
    transition: fill $transition-period;
  }

  &.content-button-light {
    color: $light-font-color;

    > svg {
      fill: $light-font-color;
    }
  }

  // keep this below themes so that focus style overrides theme style
  &:focus,
  &:hover {
    color: $button-focus-color;

    > svg {
      fill: $button-focus-color;
    }
  }

  &:focus {
    outline: none;
  }

  &.content-button-disabled {
    &,
    &:focus,
    &:hover {
      cursor: not-allowed;
      color: $disabled-font-color;

      > svg {
        fill: $disabled-font-color;
      }
    }
  }
}
