@import './shared';

.vocab-card {
  .ant-card-body {
    padding: $component-padding-md;
  }

  &.vocab-card-highlighted {
    .ant-card-body {
      background: $highlight-color;
    }
  }
}
