@import './shared';

.vocab-cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 (-$component-padding-md) (-$component-padding-md) 0;

  .vocab-cards-card {
    flex: auto;
    margin: 0 $component-padding-md $component-padding-md 0;
  }
}

.vocab-cards-no-items-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $tab-panel-min-height;
}

.vocab-cards-no-items-message {
  font-size: 80px;
  text-align: center;
}

.back-to-top-button {
  bottom: 30px;
  right: 53px;

  @include media(tablet) {
    right: 10px;
  }
}
