@import './shared';

.answer-panel-controls {
  display: grid;
  grid-gap: $component-padding-lg;

  &.answer-panel-controls-hidden {
    display: none;
  }
}

.answer-panel-progress-bar.ant-progress {
  margin-top: $component-padding-sm;
}
