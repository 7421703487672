@import './shared';

$size: 48px;

.kanji-selection {
  width: $size;
  height: $size;
  font-size: floor($size * 0.6);
  line-height: 0;

  &:focus,
  &:hover {
    $red: #ff4d4f;

    background: $red;
    border-color: $red;
    color: $light-font-color;
  }

  &:focus {
    border-color: #40a9ff;
  }

  &:not(:hover):not(:focus) {
    background: #f5f5f5;
    border-color: #d9d9d9;
    color: $kanji-selection-color;
  }
}
