@import './shared';

.settings-panel {
  .ant-collapse-header {
    font-size: $font-size-lg;
  }

  .ant-form-extra,
  .ant-collapse-content {
    color: $primary-font-color;
  }
}
