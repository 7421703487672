@import './shared';

.app-content,
.app-content-area {
  // to fill page height regardless of content
  flex: auto;
}

.app-content {
  flex: auto;
  display: flex;
  margin-top: $content-area-padding;
  padding: 0 $page-padding-horizontal;

  @include media(tablet) {
    margin-top: 0;
    padding: 0;
  }
}

.app-content-area {
  padding: $content-area-padding;
  background: $content-area-color;
}
