$app-min-width: 320px;
$page-padding-horizontal: 50px;
$content-area-padding: 25px;
$component-padding-sm: 4px;
$component-padding-md: 8px;
$component-padding-lg: 16px;
$header-padding-vertical: $component-padding-md;
$footer-padding-vertical: $component-padding-lg;
$header-padding-horizontal-lg: $page-padding-horizontal;
$footer-padding-horizontal-lg: $header-padding-horizontal-lg;
$header-padding-horizontal-sm: $component-padding-md;
$footer-padding-horizontal-sm: $header-padding-horizontal-sm;
$app-title-front-size: 16px;
$app-title-line-height: 1.5;
$app-title-height: $app-title-front-size * $app-title-line-height;
$header-height: $app-title-height + ($header-padding-vertical * 2);
$main-content-min-width-desktop: 320px;
$main-content-sticky-top: $header-height + $content-area-padding;
$tab-panel-min-height: 300px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;
$normal-font-weight: 400;
$bold-font-weight: 600;
$primary-font-color: rgba(0, 0, 0, 0.65);
$secondary-font-color: rgba(0, 0, 0, 0.55);
$disabled-font-color: rgba(0, 0, 0, 0.25);
$light-font-color: rgb(255, 255, 255);
$raised-button-font-color: rgb(46, 46, 46);
$raised-button-background-color: rgb(255, 255, 255);
$raised-button-disabled-font-color: rgb(255, 255, 255);
$raised-button-disabled-background-color: rgb(80, 80, 80);
$kanji-selection-color: rgb(241, 0, 161);
$highlight-color: rgb(253, 253, 150);
$button-focus-color: rgb(64, 169, 255);
$browser-focus-outline-color: rgb(77, 144, 254);
$disabled-input-border-color: rgb(217, 217, 217);
$page-color: rgb(240, 242, 245);
$content-area-color: rgb(255, 255, 255);
$header-color: rgb(0, 21, 41);
