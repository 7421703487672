.shake-animation {
  display: inline-block;
  animation: shake 300ms linear;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  8%,
  41% {
    transform: translateX(-5px);
  }

  25%,
  58% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-2px);
  }

  92% {
    transform: translateX(2px);
  }
}
